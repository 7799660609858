<template>
  <v-app class="v-app-background">
    <v-app-bar flat rounded="b-xl" color="white" :border="true" class="header">
      <template v-slot:prepend>
        <ClientOnly>
          <NuxtLink to="/" class="d-block">
            <Logo />
          </NuxtLink>
        </ClientOnly>
      </template>
      <template v-slot:append>
        <template v-if="!mobile">
          <NuxtLink target="_blank" :to="'https://cloud.treqit.com/register'">
            <TextButton class="mx-3">Start hosting</TextButton>
          </NuxtLink>
          <NuxtLink target="_blank" :to="'https://cloud.treqit.com/login'">
            <SecondaryButton class="mx-3">Sign in</SecondaryButton>
          </NuxtLink>
          <NuxtLink :to="'/signup'">
            <PrimaryButton class="mx-3">Sign up</PrimaryButton>
          </NuxtLink>
          <v-divider length="44" vertical class="h-100 align-self-center mx-5"></v-divider>

          <div class="header-cart" v-if="route.fullPath.includes('cart') == false" style="width: 50px;">
            <ClientOnly>
              <NuxtLink :to="`/cart`">
                <svg class="cart-button" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <!-- <rect x="0.5" y="2.5" width="43" height="43" rx="21.5" stroke="#3C464D" /> -->
                 <rect v-if="cartStore.length > 0" x="21" y="3" width="14" height="14" rx="7" fill="#3C9273" /> 

                  <text v-if="cartStore.length > 0" x="25" y="14" font-family="Poppins" font-weight="700" font-size="11"
                    fill="#FCFFFE">{{
                      cartStore.length }}</text>
                  <CartIcon />
                </svg>
              </NuxtLink>
            </ClientOnly>
          </div>
        </template>
        <div v-if="mobile">
          <button @click="drawer = !drawer">
            <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z"
                fill="#3C9273" />
            </svg>
          </button>
        </div>
      </template>
    </v-app-bar>
    <v-main class="main">
      <slot></slot>
    </v-main>
    <v-footer color="white" class="footer">
      <div fluid class="pa-3 center-content">
        <!-- <v-divider class="mb-12"></v-divider> -->
        <v-row class="mb-4">
          <v-col cols="12" md="4">
            <div class="logo-wrapper">
              <Logo />
            </div>
            <SocialLinks />
          </v-col>
          <v-col cols="12" md="8">
            <FooterMenu />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FooterCopy />
          </v-col>
        </v-row>
      </div>
    </v-footer>
    <ClientOnly>
      <v-navigation-drawer v-model="drawer" temporary location="right">
        <div class="mobile-menu-wrapper">
          <div class="mobile-menu">
            <div class="mobile-menu__item">
              <NuxtLink to="#">Start hosting</NuxtLink>
            </div>
            <div class="mobile-menu__item">
              <NuxtLink to="#">Sign in</NuxtLink>
            </div>
            <div class="mobile-menu__item">
              <NuxtLink to="/signup">Sign up</NuxtLink>
            </div>
          </div>
        </div>
      </v-navigation-drawer>
    </ClientOnly>
  </v-app>
</template>
<script setup>
import Logo from '@/components/layout/Logo.vue'
import SocialLinks from '@/components/layout/SocialLinks.vue'
import FooterCopy from '@/components/layout/FooterCopy.vue'
import FooterMenu from '@/components/layout/FooterMenu.vue'
import CartIcon from '@/components/icons/CartIcon.vue'
import TextButton from '@/components/shared/buttons/TextButton.vue'
import SecondaryButton from '@/components/shared/buttons/SecondaryButton.vue'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton.vue'
import { useDisplay } from 'vuetify'
import { onMounted } from 'vue'
import { ClientOnly } from '#components'
import { routeLocationKey } from 'vue-router'

let cartStore = useCartStore().cart;
const { currentProperty } = storeToRefs(usePropertyStore());
const { mobile } = useDisplay()
const drawer = ref(false)
let route = useRoute();

onMounted(() => {
})
</script>

<style lang="scss" scoped>
.center-content {
  margin-right: auto;
  margin-left: auto;
}
.v-app-background {
  background-color: white !important;
}

.header {
  padding: 19px 90px !important;
}

.main {
  max-width: 2000px;
  padding: 105px 100px 0;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.footer {
  padding: 48px 88px 0;
  flex: 0 0 auto;
  background-image: url('@/assets/img/footer-bg.jpg');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.logo-wrapper {
  margin-bottom: 40px;
}

.v-navigation-drawer {
  &::before {
    display: block;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 64px;
    background: white;
  }
}

.mobile-menu-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-menu {
  padding-top: 20px;
}

.mobile-menu__item {
  font-size: 18px;
  padding: 12px 20px;

  a {
    text-decoration: none;
    color: #3c464d;
  }
}

@media screen and (max-width: 1439px) {
  .header {
    padding: 19px 40px !important;
  }

  .main {
    padding: 105px 40px 0;
  }

  .footer {
    padding: 48px 40px 0;
  }
}

@media screen and (max-width: 1239px) {
  .header {
    padding: 19px 20px !important;
  }

  .main {
    padding: 105px 20px 0;
  }

  .footer {
    padding: 48px 20px 0;
  }
}

@media screen and (max-width: 899px) {
  .header {
    padding: 0 16px !important;
  }

  .main {
    padding: 64px 16px 0;
  }

  .footer {
    padding: 24px 16px;
  }

  .logo-wrapper {
    margin-bottom: 20px;
  }
}
</style>
